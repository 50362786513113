
<template>
  <div id="index" ref="appRef">
    <div class="screen-bg">
      <dv-loading v-if="!show">Loading...</dv-loading>
      <div
        v-else
        class="host-body df"
        style="flex-direction: column; width: 100%; box-sizing: border-box"
      >
        <div
          class="d-flex jc-center"
          style="width: 100%; box-sizing: border-box"
        >
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']" />
            <div class="title">
              <span class="title-text">能耗管控</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="['#568aea', '#000000']"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div
          class="d-flex jc-between px-2"
          style="box-sizing: border-box; width: 100%; padding-bottom: 20px"
        >
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-before"></span>
              <span class="text">
                <!--                                <p-->
                <!--                                        class="disib mr15"-->
                <!--                                        style="float: left; line-height: 60px"-->
                <!--                                        @click="Collapse"-->
                <!--                                >-->
                <!--                                    <i class="iconfont iconshouqi c000 mr5"></i>-->
                <!--                                </p>-->

                <el-dropdown
                  v-if="
                    company_name &&
                    isMenu == 2 &&
                    (user.user_type == 3 || user.user_type == 4)
                  "
                  class="ml20"
                  :hide-on-click="false"
                  @command="changeCompany"
                >
                  <div
                    class="f16 col-fff"
                    style="display: flex; align-items: center; cursor: pointer"
                  >
                    <div></div>
                    <img
                      style="padding: 2px; width: 30px; height: 30px"
                      src="@/assets/image/shouye.png"
                    />
                    {{ company_name }}
                  </div>
                  <el-dropdown-menu
                    slot="dropdown"
                    style="height: auto; padding: 5px"
                  >
                    <el-dropdown-item
                      v-for="(item, index) in companyData"
                      :key="index"
                      :command="item"
                      >{{ item.company_name }}
                    </el-dropdown-item>
                    <!-- <el-dropdown-item v-if="user.user_type==3" command="company">企业切换</el-dropdown-item>
                                            <el-dropdown-item command="logout">退出</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  class="ml20 mr20"
                  :hide-on-click="false"
                  @command="LoginOut"
                >
                  <div
                    class="f16 col-fff"
                    style="
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      margin-left: 10px;
                    "
                  >
                    <div></div>
                    <img
                      style="padding: 2px; width: 30px; height: 30px"
                      src="@/assets/image/my.png"
                    />
                    {{ user.user_name }}
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      style="height: auto; padding: 5px"
                      command="logout"
                      >退出</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
            <div class="react-right ml-3">
              <!--                            <span class="text">数据分析2</span>-->
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right mr-3">
              <!--                            <span class="text fw-b">vue-big-screen</span>-->
            </div>
            <div class="react-right mr-4 react-l-s">
              <!--                            <span  class="react-after l80 f16 type-title">-->
              <!--                                {{typeTitle}}-->
              <!--                            </span>-->

              <span class="react-after1 l100">
                <el-popover
                  placement="bottom"
                  width="100%"
                  v-model="showPopover1"
                  transition="el-zoom-in-top"
                >
                  <div class="box-popover">
                    <div @click="conditionFilter(1)" class="tc bgc-1A3 col-fff">
                      条件筛选
                    </div>
                    <div @click="conditionFilter(0)" class="tc bgc-1A3 col-fff">
                      全部
                    </div>
                  </div>
                  <div slot="reference" class="d-flex row-center">
                    <div class="f16 type-title">
                      {{ typeTitle }}
                    </div>
                    <div>
                      <i class="el-icon-s-data"></i>
                    </div>
                  </div> </el-popover
              ></span>
              <span class="react-after l200">
                <el-popover
                  placement="bottom"
                  width="100%"
                  v-model="showPopover"
                  transition="el-zoom-in-top"
                >
                  <div class="box-popover">
                    <div @click="showSetting" class="tc bgc-1A3 col-fff">
                      配置
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-s-tools"></i> </el-popover
              ></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>
        <div
          style="
            padding: 20px;
            box-sizing: border-box;
            flex: 1;
            height: 0;
            width: 100%;
          "
        >
          <div class="container">
            <div class="collect">
              <dv-border-box-1
                style="padding-top: 40px; box-sizing: border-box"
              >
                <div
                  class="df aic jcc"
                  style="height: 20%; font-size: 28px; position: relative"
                >
                  <div
                    style="
                      position: absolute;
                      top: 20px;
                      left: 20px;
                      width: 100px;
                      height: 100%;
                    "
                  >
                    <el-popover
                      placement="bottom"
                      width="100%"
                      trigger="click"
                      v-model="showTotalPopover"
                      transition="el-zoom-in-top"
                    >
                      <div style="padding: 20px">
                        <div class="p15 tc f16">时间筛选</div>
                        <div>
                          <el-date-picker
                            v-model="value1"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                          >
                          </el-date-picker>
                        </div>
                        <div class="mt10 tr">
                          <button class="btn-reset" @click="timeConfirm1(0)">
                            重置
                          </button>
                          <button class="btn-add" @click="timeConfirm1(1)">
                            确定
                          </button>
                        </div>
                      </div>
                      <div slot="reference" class="box-i-time1">
                        <i class="el-icon-time i-time"></i>
                      </div>
                    </el-popover>
                  </div>
                  耗电统计
                </div>
                <div class="df aic jcc" style="height: 10%; font-size: 30px">
                  <dv-decoration-5 style="width: 300px; height: 20px" />
                </div>
                <p style="height: 40%; font-size: 60px">
                  <countTo
                    :startVal="startCollectData"
                    :endVal="endCollectData"
                    :duration="3000"
                    suffix="W"
                    :decimals="decimals"
                  ></countTo>
                </p>
              </dv-border-box-1>
            </div>
            <div
              class="sensor"
              style="padding-top: 20px; box-sizing: border-box"
            >
              <dv-border-box-10 v-if="show" style="height: 100%">
                <div
                  class="df"
                  style="padding: 20px; height: 100%; flex-direction: column"
                >
                  <div class="df aic jcc">
                    <dv-decoration-11 style="width: 200px; height: 60px">
                      传感器状态
                    </dv-decoration-11>
                  </div>
                  <div
                    style="
                      flex: 1;
                      height: 0;
                      padding: 20px 0;
                      box-sizing: border-box;
                    "
                  >
                    <dv-loading
                      class="loading"
                      v-show="charts3Loading"
                    ></dv-loading>
                    <div
                      style="height: 100%; overflow: hidden"
                      v-if="!charts3Loading"
                    >
                      <vue-seamless-scroll
                        :data="listData1"
                        style="height: 306px"
                        class="seamless-warp"
                        :class-option="optionSetting"
                      >
                        <!-- :data="listData" -->
                        <!-- <ul>
                        <li v-for="(item,index) in listData" :key="index">{{ item }}</li>
                      </ul> -->
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          class="item"
                        >
                          <tbody>
                            <tr v-for="(item, index) in listData1" :key="index">
                              <td width="50%">
                                <span>{{ item.deviceName }}</span>
                              </td>
                              <td width="50%">
                                <span
                                  :class="
                                    item.status === 'OFFLINE'
                                      ? 'colorRed'
                                      : 'colorGreen'
                                  "
                                  >{{
                                    item.status === "OFFLINE" ? "离线" : "在线"
                                  }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- <div>
                          <div
                            v-for="(item, index) in listData1"
                            :key="index"
                            class="df aic"
                          >
                            <div style="padding-right: 20px">
                              {{ item.deviceName }}
                            </div>
                            <div>
                              {{ item.status }}
                            </div>
                          </div>
                        </div> -->
                      </vue-seamless-scroll>
                    </div>
                  </div>
                </div>
              </dv-border-box-10>
            </div>
            <div
              class="charts1"
              style="padding-left: 20px; box-sizing: border-box"
            >
              <dv-border-box-13 style="padding: 20px">
                <div
                  class="df"
                  style="flex-direction: column; width: 100%; height: 100%"
                >
                  <div class="df aic" style="width: 100%">
                    <div style="flex: 1; width: 0">
                      <el-tabs
                        v-model="activeName"
                        @tab-click="handleClick"
                        stretch
                      >
                        <el-tab-pane label="全部" name="all"></el-tab-pane>
                        <el-tab-pane
                          v-for="item in tagList1"
                          :key="item.id"
                          :label="item.name"
                          :name="item.name"
                        ></el-tab-pane>
                      </el-tabs>
                    </div>
                    <div class="df aic jcc" style="flex: 0 0 100px">
                      <el-button type="text" @click="openTag">
                        <i
                          class="el-icon-s-tools"
                          style="font-size: 30px; color: #fff"
                        ></i>
                      </el-button>
                    </div>
                  </div>
                  <div style="flex: 1; height: 0">
                    <div style="width: 100%; height: 100%">
                      <dv-loading
                        class="loading"
                        v-show="charts2Loading"
                      ></dv-loading>
                      <energy-charts
                        v-if="!charts2Loading"
                        :cdata="charts2Data"
                      />
                    </div>
                  </div>
                </div>
              </dv-border-box-13>
            </div>
            <div
              class="charts2"
              style="padding-top: 20px; box-sizing: border-box"
            >
              <dv-border-box-12
                style="
                  padding: 20px 20px 0px;
                  width: 100%;
                  height: 100%;
                  position: relative;
                "
              >
                <dv-loading
                  class="loading"
                  v-show="charts3Loading"
                ></dv-loading>
                <!-- <energy-charts-Line
                  v-if="!charts3Loading"
                  :cdata="charts3Data"
                /> -->
                <div
                  v-if="!charts3Loading"
                  class="df"
                  style="
                    height: 100%;
                    box-sizing: border-box;
                    flex-direction: column;
                  "
                >
                  <div class="df aic" style="height: 46px">
                    <el-select
                      v-if="collectQuery.startTime || collectQuery.endTime"
                      v-model="timeStatus"
                      placeholder="请选择"
                      @change="timeStatusChange"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="box-sizing: border-box">
                    <div style="height: 380px; overflow: hidden">
                      <energy-charts-Line
                        v-if="!charts3Loading && charts3Data"
                        :cdata="charts3Data"
                      />
                    </div>
                  </div>
                </div>
              </dv-border-box-12>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <template slot="title">
        <div class="tl p10i">
          <span>
            <el-input
              prefix-icon="el-icon-search"
              placeholder="请输入搜索内容"
              v-model="sensorQuery.keyword"
              width="200px"
              clearable
            >
            </el-input>
          </span>
          <span>
            <button class="btn-add" @click="searchSensor">搜索</button>
          </span>
        </div>
      </template>
      <div style="height: 600px; overflow-y: scroll">
        <el-table ref="multipleTable" :data="sensorList">
          <!--                <el-table-column-->
          <!--                        type="selection"-->
          <!--                        width="55">-->
          <!--                </el-table-column>-->
          <el-table-column
            property="deviceName"
            label="传感器名称"
            width="150"
          ></el-table-column>
          <el-table-column
            property="deviceId"
            label="设备id"
            width="200"
          ></el-table-column>
          <el-table-column
            property="unit"
            label="单位"
            width="60"
          ></el-table-column>
          <el-table-column width="350" label="标签">
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.tagListStr && JSON.parse(scope.row.tagList).length
                "
              >
                <el-tag
                  size="small"
                  closable
                  style="margin: 5px; padding: 0 8px"
                  @close="
                    removeSensorTag(
                      scope.row,
                      JSON.parse(scope.row.tagList)[index]
                    )
                  "
                  v-for="(item, index) in scope.row.tagListStr"
                  :key="JSON.parse(scope.row.tagList)[index]"
                  >{{ item }}</el-tag
                >
              </template>

              <span class="sensor-add-btn">
                <el-dropdown
                  trigger="click"
                  @command="(data) => addSensorTag(scope.row, data)"
                >
                  <el-button type="primary" size="small">
                    <i class="el-icon-plus el-icon--right"></i> 添加标签
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                    <el-dropdown-item
                      v-for="item in tagList1"
                      :key="item.id"
                      :command="item.id"
                      :disabled="
                        scope.row.tagList &&
                        JSON.parse(scope.row.tagList).includes(item.id)
                      "
                      >{{ item.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <el-popover placement="bottom" width="80" trigger="click">
                  <div style="scroll-y: scroll; max-height: 100px; width: 80px">
                    <div class="df aic jcc">
                      测试长度123123
                    </div>
                  </div> -->
                <!-- <el-button slot="reference" size="small">click 激活</el-button> -->
                <!-- </el-popover> -->
              </span>
            </template>
          </el-table-column>
          <el-table-column width="50" label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="color: #f56c6c"
                v-if="scope.row.showScreen"
                @click="sensorJoinChange(scope.row.id, false)"
                >删除</el-button
              >
              <el-button
                type="text"
                v-else
                @click="sensorJoinChange(scope.row.id, true)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt10">
        <el-pagination
          layout="prev, pager, next"
          :current-page="sensorQuery.page"
          @current-change="currentChange"
          :total="sensorTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogTagVisible" :close-on-click-modal="false">
      <template slot="title">
        <div class="tl p10i">
          <span>
            <el-input
              prefix-icon="el-icon-search"
              placeholder="请输入搜索内容"
              v-model="tagQuery.keyword"
              width="200px"
              clearable
            >
            </el-input>
          </span>
          <span>
            <button class="btn-add" @click="searchTag">搜索</button>
          </span>
          <span>
            <el-popover
              placement="bottom"
              width="400"
              trigger="click"
              v-model="addTagvisible"
            >
              <div style="padding: 20px">
                <el-form ref="form" :model="tagForm" label-width="80px">
                  <el-form-item label="标签名称">
                    <div style="width: 140%">
                      <el-input v-model="tagForm.name"></el-input>
                    </div>
                  </el-form-item>
                </el-form>
                <div
                  class="df add-btn"
                  style="justify-content: flex-end; padding-top: 5px"
                >
                  <el-button @click="handleCancel" size="small">取消</el-button>
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleTagSubmit"
                    >确定</el-button
                  >
                </div>
              </div>
              <button class="btn-add" slot="reference">新增</button>
            </el-popover>
          </span>
        </div>
      </template>

      <el-table ref="multipleTable" :data="tagList">
        <!--                <el-table-column-->
        <!--                        type="selection"-->
        <!--                        width="55">-->
        <!--                </el-table-column>-->
        <el-table-column property="name" label="标签名称" width="150">
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">{{ scope.row.name }}</div>
            <el-input
              v-model="scope.row.name"
              v-else
              style="width: 100%"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          property="createdTime"
          label="创建时间"
          width="200"
        ></el-table-column>
        <el-table-column
          property="createdName"
          label="创建人"
          width="200"
        ></el-table-column>
        <el-table-column width="50" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="!scope.row.isEdit"
              @click="scope.row.isEdit = true"
              >修改</el-button
            >
            <el-button
              type="text"
              style="color: #67c23a"
              v-else
              @click="tagUpdate(scope.row)"
              >完成</el-button
            >
            <el-button
              type="text"
              style="color: #f56c6c"
              @click="tagDel(scope.row.id)"
              >删除</el-button
            >
            <!-- <button
              class="btn-del"
              v-if="showScreen"
              @click="handleDelete(scope.$index, scope.row)"
            >
              
            </button>
            <button
              class="btn-del"
              v-else
              @click="handleDelete(scope.$index, scope.row)"
            >
              
            </button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="mt10">
        <el-pagination
          layout="prev, pager, next"
          :current-page="tagQuery.page"
          @current-change="currentChange1"
          :total="tagTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import drawMixin from "@/util/drawMixin";
import { formatTime } from "@/util/util";
import vueSeamlessScroll from "vue-seamless-scroll";

import countTo from "vue-count-to";
import api from "../../../util/extra-api";
import axios from "../../../util/api";
import { local } from "../../../util/util";

import EnergyCharts from "./components/energyCharts.vue";
import EnergyChartsLine from "./components/energyCharts1.vue";
// import bottomRight from './bottomRight'

export default {
  mixins: [drawMixin],
  props: {
    companyData: {
      type: Array,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      sensorQuery: {
        keyword: "",
        page: 1,
        limit: 10,
      },
      companyId: null,
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      startMoney: 0,
      totalMoney: 0,
      chart1Data: null,
      chart2Data: null,
      decimals: 2,
      show: false,
      showPopover: false,
      showPopover1: false,
      dialogTableVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      gridData: [],
      activeIndex: "1",
      dialogAddgsVisible: false,
      title: "添加物资",
      autoPlaceholder: "请输入物资名称",
      goodsInfo: {
        goodsName: "",
        gtin: "",
      },
      keyword: "",
      menuType: 0,
      total: null,
      currentPage: null,
      typeTitle: "全部",
      type: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      startTime1: "",
      endTime1: "",
      showTotalPopover: false,
      screenType: 0,
      company_name: local.get("company_name"),
      isMenu: local.get("isMenu"),

      sensorList: [],
      sensorTotal: 0,
      collectQuery: {
        startTime: "",
        endTime: "",
        companyId: null,
      },
      startCollectData: 0,
      endCollectData: 0,
      activeName: "all",
      tagList: [],
      tagList1: [],
      tagTotal: 0,
      tagQuery: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      tagQuery1: {
        page: 1,
        limit: 100,
        keyword: "",
      },
      dialogTagVisible: false,
      tagForm: {
        icon: "el-icon-price-tag",
        name: "",
        type: 3,
      },
      addTagvisible: true,
      checkedId: null,
      charts2Data: null,
      charts2Loading: true,
      // showTotalPopover1:false,
      charts3Loading: true,
      charts3Data: null,
      options: [
        // {
        //   value: 0,
        //   label: "月",
        // },
        {
          value: 2,
          label: "日",
        },
        {
          value: 1,
          label: "小时",
        },
      ],
      timeStatus: 0,
    };
  },
  components: {
    countTo,
    EnergyCharts,
    EnergyChartsLine,
    vueSeamlessScroll,
  },
  computed: {
    listData1() {
      if (!(this.charts3Data.y && this.charts3Data.y.length)) {
        console.log(123);
        return [];
      } else {
        return this.charts3Data.y.map((v) => {
          return {
            deviceName: v.deviceName,
            status: v.status,
          };
        });
      }
    },
    optionSetting() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // autoPlay: false,
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    this.collectQuery.companyId = localStorage.getItem("company_id");
    this.getCharts();
    if (localStorage.getItem("time5")) {
      let time1 = JSON.parse(localStorage.getItem("time5"));
      this.value1 = [time1.startTime, time1.endTime];
      this.startTime1 = time1.startTime;
      this.endTime1 = time1.endTime;
    }

    this.show = true;

    this.companyId = localStorage.getItem("company_id");
    // console.log("getSensorList0");
    this.getTagList1();
    this.getSensorList();

    this.getType();
    // this.timeFn();
    // this.getChart1();
    // this.getChart1_2();
    // this.cancelLoading();
    // this.getGoodsAndMaterialsList();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeStatusChange(value) {
      console.log(value);
      this.getCharts3(this.checkedId);
    },
    removeSensorTag(data, id) {
      // console.log(data, id);
      api
        .post("/v1/pc/energy/removeTag", {
          deviceId: data.id,
          tagId: id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "移除成功",
              type: "success",
            });
            this.getSensorList();
            if (this.checkedId == id) {
              this.getCharts();
              this.getCharts2(id);
              this.getCharts3(id);
            }
          }
        });
    },
    addSensorTag(data, id) {
      api
        .post("/v1/pc/energy/addTag", {
          deviceId: data.id,
          tagId: id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getSensorList();
            if (this.checkedId == id) {
              this.getCharts();
              this.getCharts2(id);
              this.getCharts3(id);
            }
          }
        });
    },
    tagDel(id) {
      api.post("/v1/pc/energy/deleteTag", { id }).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getTagList();
          this.getTagList1();
          if (this.checkedId == id) {
            this.getCharts();
            this.getCharts2(-1);
            this.getCharts3(-1);
          }
        }
      });
    },
    tagUpdate(data) {
      api
        .post("/v1/pc/energy/updateTag", {
          id: data.id,
          icon: data.icon,
          name: data.name,
          type: data.type,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getTagList();
            this.getTagList1();
          }
        });
    },
    handleCancel() {
      this.addTagvisible = false;
    },
    handleTagSubmit() {
      api
        .post("/v1/pc/energy/saveTag", {
          ...this.tagForm,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.tagForm.name = "";
            this.getTagList();
            this.getTagList1();
            this.addTagvisible = false;
          }
        });
    },
    openTag() {
      this.getTagList();
      this.dialogTagVisible = true;
    },
    getTagList() {
      api
        .get("/v1/pc/energy/tagList", {
          ...this.tagQuery,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data, 123);
            this.tagList = res.data.map((v) => {
              return {
                ...v,
                isEdit: false,
              };
            });
            this.tagTotal = res.total;
          }
        });
    },
    getTagList1() {
      api
        .get("/v1/pc/energy/tagList", {
          ...this.tagQuery1,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data, 123);
            this.tagList1 = res.data;
            if (this.checkedId === null) {
              this.getCharts();
              this.getCharts2(-1);
              this.getCharts3(-1);
            }
          }
        });
    },

    handleClick(data) {
      console.log(data.index);
      if (data.index == 0) {
        this.getCharts();
        this.getCharts2(-1);
        this.getCharts3(-1);
      } else {
        // console.log(this.tagList1[data.index - 1].id, 123);
        this.getCharts();
        this.getCharts2(this.tagList1[data.index - 1].id);
        this.getCharts3(this.tagList1[data.index - 1].id);
      }
      // this.getCharts2()
    },
    getCharts() {
      api.get("/v1/pc/energy/chart1", this.collectQuery).then((res) => {
        if (res.code === 200) {
          // console.log(res.data, 123);
          this.startCollectData = this.endCollectData;
          this.endCollectData = res.data;
        }
      });
    },
    getCharts2(id) {
      this.charts2Loading = true;
      this.checkedId = id;
      api
        .get("/v1/pc/energy/chart2", {
          ...this.collectQuery,
          tag: id,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data, 123);
            // this.startCollectData = this.endCollectData;
            // this.endCollectData = res.data;
            this.charts2Data = res.data;
            // this.charts2Data = new Array(20).fill(0).map((v,i)=>{
            //   return{
            //     x:res.data[0].x+i,
            //     y:res.data[0].y+i*30,
            //   }
            // })
            this.charts2Loading = false;
          }
        })
        .catch((err) => {
          this.charts2Loading = false;
        });
    },
    getCharts3(id) {
      this.charts3Loading = true;
      api
        .get("/v1/pc/energy/chart3", {
          ...this.collectQuery,
          tag: id,
          type: this.timeStatus,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data, 123);
            // this.startCollectData = this.endCollectData;
            // this.endCollectData = res.data;
            this.charts3Data = res.data;
            // console.log(this.listData1, 123);
            // this.charts2Data = new Array(20).fill(0).map((v,i)=>{
            //   return{
            //     x:res.data[0].x+i,
            //     y:res.data[0].y+i*30,
            //   }
            // })
            this.charts3Loading = false;
          }
        })
        .catch((err) => {
          this.charts3Loading = false;
        });
    },

    searchSensor() {
      this.sensorQuery.page = 1;
      this.getSensorList();
    },
    searchTag() {
      this.tagQuery.page = 1;
      this.getTagList();
    },
    getSensorList() {
      //console.log("getSensorList1");
      api
        .get("/v1/pc/energy/list", {
          ...this.sensorQuery,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code == 200) {
            // this.show = true;
            console.log(res.data, 123);
            this.sensorList = res.data;
            this.sensorTotal = res.total;
          }
        });
    },
    // tagQuery1
    sensorJoinChange(id, join) {
      api
        .post("/v1/pc/energy/joinTj", {
          deviceId: id,
          companyId: this.companyId,
          join,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getSensorList();
          }
        });
    },
    timeConfirm1(type) {
      if (type === 1) {
        this.collectQuery.startTime = formatTime(
          this.value1[0],
          "yyyy-MM-dd HH:mm:ss"
        );
        this.collectQuery.endTime = formatTime(
          this.value1[1],
          "yyyy-MM-dd HH:mm:ss"
        );
        if (this.timeStatus === 0) {
          this.timeStatus = 2;
        }
      } else {
        this.timeStatus = 0;
        this.collectQuery.startTime = "";
        this.collectQuery.endTime = "";
        this.value1 = ["", ""];
      }
      // console.log(this.collectQuery, "进入");
      this.getCharts();
      this.getCharts2(this.checkedId);
      this.getCharts3(this.checkedId);
      // let time1 = {
      //   startTime: this.startTime1,
      //   endTime: this.endTime1,
      // };
      // localStorage.setItem("time5", JSON.stringify(time1));

      // this.getChart1_2();
      this.showTotalPopover = false;
    },
    getType() {
      this.type = localStorage.getItem("type");
      if (this.type == 0 || !this.type) {
        this.typeTitle = "全部";
      } else {
        this.typeTitle = "条件筛选";
      }
    },
    conditionFilter(type) {
      localStorage.setItem("type", type);
      location.reload();
    },
    refreshScreen() {
      location.reload();
    },
    currentChange(page) {
      // this.currentPage = page;
      //   this.listQuery.page = page;
      this.sensorList.query = page;
      this.getSensorList();
    },
    currentChange1(page) {
      this.tagQuery.page = page;
      this.getTagList();
    },
    showSetting() {
      this.dialogTableVisible = true;
      this.getSensorList();
      //   this.getGoodsAndMaterialsList();
    },
    // handleDelete(index, row) {
    //   let data = {
    //     companyId: this.companyId,
    //     id: row.id,
    //   };
    //   api
    //     .get("/v1/wx/companyScreen/deleteGoodsAndMaterials", data)
    //     .then((res) => {
    //       if (res.code === 200) {
    //         this.$notify({
    //           title: "成功",
    //           message: "删除成功",
    //           type: "success",
    //         });
    //         this.getGoodsAndMaterialsList();
    //       }
    //     });
    // },
    // getGoodsAndMaterialsList() {
    //   let data = {
    //     companyId: this.companyId,
    //     type: this.menuType,
    //     ...this.listQuery,
    //   };
    //   api
    //     .get("/v1/wx/companyScreen/goodsAndMaterialsList", data)
    //     .then((res) => {
    //       //console.log("goodsAndMaterialsList",res.data)
    //       if (res.code === 200) {
    //         this.gridData = res.data;
    //         this.total = res.total;
    //       }
    //     });
    // },
    // closeDialogAddgsVisible() {
    //   this.dialogAddgsVisible = false;
    // },
    // querySearchAsync(queryString, cb) {
    //   if (queryString) {
    //     api
    //       .get("/v1/wx/goods/pcGoodsList", {
    //         keyword: this.goodsInfo.goodsName,
    //         companyId: this.companyId,
    //         type: this.menuType,
    //       })
    //       .then((response) => {
    //         let results = response.data;
    //         cb(results);
    //       });
    //   } else {
    //     cb([]);
    //   }
    // },
    // getChart1() {
    //   let data = {
    //     companyId: this.companyId,
    //     type: this.type,
    //   };
    //   api.get("/v1/wx/companyScreen/chart1", data).then((res) => {
    //     if (res.code === 200) {
    //       this.chart1Data = Object.assign({}, res.data);
    //       this.chart2Data = res.data.companyGoodsRankLists;
    //       // this.totalMoney = res.data.pureProfit
    //       this.show = true;
    //     }
    //   });
    // },
    // getChart1_2() {
    //   let data = {
    //     companyId: this.companyId,
    //     type: this.type,
    //     startTime: this.startTime1,
    //     endTime: this.endTime1,
    //   };
    //   api.get("/v1/wx/companyScreen/chart1_2", data).then((res) => {
    //     if (res.code === 200) {
    //       this.totalMoney = res.data.pureProfit;
    //     }
    //   });
    // },
    // timeFn() {
    //   this.timing = setInterval(() => {
    //     this.dateDay = formatTime(new Date(), "HH: mm: ss");
    //     this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
    //     this.dateWeek = this.weekday[new Date().getDay()];
    //   }, 1000);
    // },
    // cancelLoading() {
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 500);
    // },
    changeCompany(command) {
      local.set("isMenu", 2);
      local.set("company_id", command.company_id);
      local.set("company_name", command.company_name);
      this.goCompany(command.company_id);
    },
    goCompany(params) {
      axios
        .put("/pc/user-role/refresh/menu", { last_login_company_id: params })
        .then((v) => {
          this.menu_list = v.data.menu_list;
          local.set("menu_list", this.menu_list);
          this.url = v.data.menu_list[0].menu_list[0].menu_url;
          this.company_name = local.get("company_name");
          if (this.$route.path !== this.url) {
            this.$router.replace({
              path: this.url,
            });
          }
        });
    },
    LoginOut(command) {
      let token = local.get("token");
      if (command === "logout") {
        axios.get("/pc/user/logOut", { token: token }).then((v) => {
          local.clear();
          this.$router.replace({
            path: "/login",
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
@import "../../../assets/scss/style.scss";
.el-menu.el-menu--horizontal {
  display: flex;
  justify-content: space-around;
}
.mt10 {
  margin-top: 10px !important;
}
.el-input {
  width: 50% !important;
}
.p10i {
  padding: 0 0 10px 0 !important;
}

.host-body {
  height: 100%;
}

.container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.4fr 0.9fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "collect charts1 charts1"
    "sensor charts1 charts1"
    "charts2 charts2 charts2";
}

.sensor {
  grid-area: sensor;
}

.charts1 {
  grid-area: charts1;
}

.charts2 {
  grid-area: charts2;
}

.collect {
  grid-area: collect;
}
/deep/ .el-tabs__item {
  color: #fff;
}
/deep/ .is-active {
  color: #409eff;
}
/deep/ .el-tabs__nav-next {
  color: #fff;
}
/deep/ .el-tabs__nav-prev {
  color: #fff;
}
.add-btn {
  /deep/ .el-button {
    padding: 9px 15px;
  }
}
.sensor-add-btn {
  /deep/ .el-button {
    margin: 5px;
    padding: 6px 8px;
  }
}
/deep/ .el-dropdown-menu__item {
  padding: 0 20px;
}
// /deep/ .el-dropdown-menu {
//   max-height: 300px;
//   overflow-y: scroll;
// }
.dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}
/deep/ .el-select-dropdown__item {
  padding: 0 20px !important;
}

%table-style {
  width: 100%;
  height: 35px;
  table-layout: fixed;
  tr {
    td {
      padding: 10px 5px;
      text-align: center;
      background-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      color: #e2e5ff;
      font-size: 16px;
    }
  }
}

.seamless-warp {
  height: 400px;
  overflow: hidden;
  visibility: visible;
  .colorRed {
    color: #ff4669;
  }
  .colorGreen {
    color: #45b97c;
  }
  .item {
    height: auto;
    @extend %table-style;
    tr {
      td {
        &.title {
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }
}
</style>
