<template>
  <Echart
    :options="options"
    id="bottomLeftChart"
    height="100%"
    width="100%"
  ></Echart>
</template>

<script>
import api from "@/util/extra-api";
import Echart from "@/common/echart";

export default {
  components: {
    Echart,
  },
  name: "energyCharts1",
  data() {
    return {};
  },
  props: {
    cdata: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    options() {
      // console.log(this.cdata, 123);
      // return {
      //   title: {
      //     text: "",
      //   },
      //   tooltip: {
      //     trigger: "axis",
      //   },
      //   legend: {
      //     data: this.cdata.y.map(v=>v.deviceName),
      //   },
      //   grid: {
      //     left: "3%",
      //     right: "4%",
      //     bottom: "3%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     type: "category",
      //     boundaryGap: false,
      //     data:this.cdata.x,
      //     axisTick: {
      //       show: false,
      //     },
      //     inverse: true,
      //   },
      //   yAxis: {
      //     name: "W",
      //     type: "value",
      //   },
      //   series:
      //   this.cdata.y.map(v=>{
      //     return{
      //       name: v.deviceName,
      //       type: "line",
      //       stack: "Total",
      //       data:v.num
      //     }
      //   }),
      // };
      return {
        title: {
          text: "",
        },
        dataZoom:this.cdata.x.length>10?[
          {
            type: "inside",
            show: true,
            start: 40,
            end: 70,
            xAxisIndex: 0,
          },
        ]:[],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC",
            },
          },
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 16,
          },
        },
        legend: {
          data: this.cdata.y.map((v) => v.deviceName),
        },
        grid: {
          x: "5%",
          width: "92%",
          top:"6%",
          y: "4%",
        },
        xAxis: {
          data: this.cdata.x,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4",
            },
          },
          axisTick: {
            show: false,
          },
          inverse: true,
        },
        yAxis: [
          {
            name: "W",
            nameGap: 5,
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
            },
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              formatter: "{value} ",
            },
          },
        ],
        series: this.cdata.y.map((v) => {
          return {
            name: v.deviceName,
            type: "line",
            stack: "Total",
            data: v.num,
          };
        }),
      };
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
</style>